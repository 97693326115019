<template>
  <div class="flex justify-center">
    <ul
      class="w-full md:w-10/12 mt-10 flex flex-wrap justify-center items-start"
    >
      <li
        class="w-5/12 md:w-3/12 my-2 mx-2 flex flex-col items-center justify-center cursor-pointer"
        v-for="(data, i) in menus"
        :key="i"
      >
        <div class="w-full bg-white rounded-t-lg" @click="routeTo(data)">
          <!--  <img
            class="m-auto h-36"
            :src="
              require('@/assets/images/community-images/' + data.img + '.png')
            "
            :alt="data.img"
          /> -->
          <img class="m-auto h-36" :src="url" :alt="data.img" />
        </div>
        <div class="w-full py-2 bg-primary">
          <span class="px-2 text-center font-proximaMedium text-white">{{
            data.name
          }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { bannerGallery } from "../../services/serviceDirectoryService";

export default {
  data() {
    return {
      url: "http://138.68.68.46/assets/e6943f87-2ca2-4688-ac00-ce41ce890e68/",
      gallery: {},
      //src={"http://138.68.68.46:8055/" + article.image}"
      menus: [
        { img: "lostPets_image", name: "Lost Pets", to: "LostPetListing" },
        {
          img: "bloodDonorDatabase_image",
          name: "Blood Donor Database",
          to: "",
        },
        { img: "discussionForum_image", name: "Discussion Forum", to: "" },
        { img: "events_image", name: "Events", to: "" },
        {
          img: "prelovedSecondHandPetProducts_image",
          name: "Preloved Swap",
          to: "",
        },
        { img: "offersPromotions_image", name: "Promotions", to: "" },
        {
          img: "animalSheltersRescues_image",
          name: "Rescues & Shelters",
          to: "",
        },
      ],
    };
  },
  async created() {
    await bannerGallery().then((response) => {
      console.log("tttt", response.data);
      //this.gallery = response.data.data;
    });
  },
  methods: {
    async routeTo(i) {
      //this.$router.push({ name: v, params: { pet: i } });
      this.$router.push({ name: i.to });
      //this.$store.commit("petStore", this.pet);
    },
  },
};
</script>

<style></style>