<template>
  <div>
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal titlemenu2="Community Space" class="pl-4" />
      </div>
    </div>
    <div class="mt-12">
      <!-- src="@/assets/images/community-banner.jpg" -->
      <img
        class="w-11/12 md:w-8/12 m-auto rounded-xl"
        :src="url"
        alt="community-banner"
      />
      <CommunityMenus />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import CommunityMenus from "./CommunityMenus.vue";
import { bannerGallery } from "../../services/serviceDirectoryService";

export default {
  components: {
    HeaderGlobal,
    CommunityMenus,
  },
  data() {
    return {
      url: "http://138.68.68.46/assets/e6943f87-2ca2-4688-ac00-ce41ce890e68/",
      gallery: {},
      img: "community_banner",
    };
  },
  async created() {
     await bannerGallery().then((response) => {
       console.log(response);
      //this.gallery = response.data.data;
    }); 
  },
};
</script>

<style></style>
