<template>
  <div class="my-6">
    <CommunitySection />
  </div>
</template>

<script>
import CommunitySection from "../components/communityoverview/CommunitySection.vue";

export default {
  components: {
    CommunitySection,
  },
};
</script>